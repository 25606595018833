import {
  CONTACT_AGREEMENT,
  CONTACT_QUARANTINE,
  LOGIN_HISTORY,
  OPERATION_HISTORY
} from "../types/reports.types.js";

export const messages = {
  en: {
    $vuetify: {
      dataIterator: {
        rowsPerPageText: "Items per page:",
        rowsPerPageAll: "All",
        pageText: "{0}-{1} of {2}",
        noResultsText: "No matching records found",
        nextPage: "Next page",
        prevPage: "Previous page"
      },
      dataTable: { rowsPerPageText: "Rows per page:" },
      noDataText: "No data available"
    }
  },
  pl: {
    $vuetify: {
      dataIterator: {
        rowsPerPageText: "Pozycji na stronie:",
        rowsPerPageAll: "Wszystkie",
        pageText: "{0}-{1} z {2}",
        noResultsText: "Nie znaleziono danych",
        nextPage: "Następna strona",
        prevPage: "Poprzednia strona"
      },
      dataTable: { rowsPerPageText: "Wierszy na stronie:" },
      noDataText: "Brak danych"
    },
    global: {
      app: "rodo",
      users: "Użytkownicy",
      roles: "Role",
      role: "Rola",
      logs: "Baza logów",
      search: "Szukaj",
      import: "Importuj",
      importFromCSV: {
        desc: "Importuj filtry z pliku CSV według wzoru:",
        name: "Imię",
        surname: "Nazwisko",
        phone: "Telefon",
        email: "E-mail",
        exampleDesc: "Przykładowy zapis w pliku CSV:",
        example: "jan;kowalski;500123456;adres@email.pl;",
        appliedFilters:
          "Brak filtrów | Zastosowane filtry ({n}): | Zastosowane filtry ({n}):"
      },
      exportToCsv: {
        desc: "Eksportuj wynik wyszukiwania do pliku CSV",
        maxExceeded:
          "Ilość rekordów przekroczyła maxymalną liczbę (50000) do eksportu CSV, prosze zawęzić poszukiwania.",
        noData: "Brak rekordów do wyeksportowania"
      },
      edit: "Edytuj",
      preview: "Podgląd",
      accept: "Zatwierdź",
      save: "Zapisz",
      cancel: "Anuluj",
      add: "Dodaj",
      remove: "Usuń",
      confirm: "Potwierdź",
      confirmText: "Czy chcesz usunąć wybrane elementy?",
      confirmTextWithCount: "Czy chcesz usunąć wybrane elementy ({count})?",
      required: "Pole {value} jest wymagane",
      noItems: "Brak elementów",
      back: "Wróć",
      print: "Drukuj",
      actions: "Akcje",
      expand: "Rozwiń",
      date: {
        only: "Data",
        from: "@:global.date.only od",
        to: "@:global.date.only do"
      },
      from: "Od",
      to: "Do",
      operationSuccess: "Operacja zakończona sukcesem",
      selectFile: "Wybierz plik",
      fileError: "Nie wybrano poprawnego pliku",
      fileParseError:
        "Błąd podczas odczytu. Proszę sprawdzić poprawność wybranego pliku.",
      quarantineSearch: "Pokaż kontakty z kwarantanny",
      generateReport: "Generuj raport",
      getFile: "Pobierz plik",
      timeout:
        "Upłynął czas oczekiwania na odpowiedź od zewnętrznych systemów dziedzinowych.",
      error: "Wystąpił nieoczekiwany błąd podczas wykonywania operacji."
    },
    settings: { changePassword: "Zmiana hasła", logout: "Wyloguj" },
    tabMenu: {
      admin: "czynności administracyjne",
      user: "czynności rodo",
      reports: "raporty"
    },
    login: {
      userName: "Login",
      password: "Hasło",
      lostPassword: "Zapomniałem hasła",
      login: "Zaloguj się",
      loadUser: "Pobieranie uprawnień",
      invalid_grant: "Wpisano niepoprawny login i/lub hasło",
      connection_error:
        "Błąd podczas komunikacji z serwerem autentykacji. Prosimy spróbować ponownie później",
      user_not_allowed:
        "Użytkownik nie jest uprawniony do korzystania z aplikacji",
      login_error: "Błąd podczas logowania",
      session_expired: "Twoja sesja wygasła. Zaloguj się ponownie.",
      unauthorized_client: "Brak dostępu to aplikacji"
    },
    users: {
      role: "Rola",
      login: "Login",
      email: "E-mail",
      restrictions: "Uprawnienia",
      user: "użytkownika",
      deleted: "Usunięto wszystkich wybranych użytkowników",
      generatePassword: "Generuj hasło",
      passwordGeneratedAndSend:
        "Wiadomość e-mail z nowym hasłem została wysłana do użytkownika"
    },
    roles: {
      search: "Nazwa roli",
      name: "Nazwa",
      restrictions: "Uprawnienia",
      role: "rolę",
      restrictionsRequired: "Wybierz przynajmniej jedno uprawnienie",
      deleted: "Usunięto wszystkie wybrane role"
    },
    rodo: {
      name: "Imię",
      surname: "Nazwisko",
      phone: "Telefon",
      mail: "E-mail",
      system: "System dziedzinowy",
      module: "Moduł",
      insertDate: "Data wprowadzenia do systemu",
      expirationDate: "Data ważności",
      desc: "Informacje dodatkowe",
      history: "Historia sprzeciwów",
      noHistory: "Brak danych klienta",
      searcherWrongLength: "Filtr musi być uzupełniony co najmniej 3 znakami",
      confirmQuarantine:
        "Czy chcesz przenieść wybrane elementy ({count}) do kwarantanny?",
      confirmUnsetQuarantine:
        "Czy chcesz zakończyć okres kwarantanny wybranych elementów ({count})?",
      isQuarantine: "Kontakt objęty kwarantanną",
      quarantine: "kwarantanna",
      finishQuarantine: "Zakończ kwarantannę",
      marketingObjection: "Kontakt wyraził sprzeciw dla przetwarzania danych",
      anonymize: "Anonimizuj",
      confirmTextWithCount:
        "Czy chcesz zanonimizować wybrane elementy ({count})?"
    },
    backup: {
      welcomText: "Wybierz przedział czasowy i naciśnij 'Szukaj'",
      getFileRange: "Pobierz wybrany zakres",
      download: "Pobierz",
      deleteRange: "Usuń wybrany zakres",
      dateFrom: "@:global.date.from",
      dateTo: "@:global.to",
      chart: {
        title: "Aktywności użytkowników w okresie od {startDate} do {endDate}",
        xAxisTitle: "Ilość operacji",
        yAxisTitle: "Nazwa aktywności"
      }
    },
    tasks: {
      title: "Działania zaplanowane automatycznie",
      expirationDate: "Data wygaśnięcia",
      insertDate: "Data wprowadzenia",
      content: "Treść",
      taskMessage: "Zbliżająca się data wygaśnięcia konta"
    },
    validation: {
      badEmailFormat: "Podany adres e-mail ma nieprawidłowy format",
      badLoginFormat:
        "Login nie może zawierać polskich liter oraz znaków specjalnych"
    },
    reports: {
      [CONTACT_AGREEMENT]: "Raport zgód marketingowych",
      [LOGIN_HISTORY]: "Raport logowania do aplikacji",
      [OPERATION_HISTORY]: "Raport działań na danych osobowych",
      [CONTACT_QUARANTINE]: "Raport kwarantanny"
    },
    marketingReport: {
      withAgreement: "Kontakty ze zgodą marketingową",
      withoutAgreement: "Kontakty bez zgody marketingowej",
      name: "Imię",
      surname: "Nazwisko",
      phone: "Telefon",
      mail: "E-mail",
      system: "System dziedzinowy",
      insertDate: "Data wprowadzenia do systemu",
      expirationDate: "Data ważności",
      insertUser: "Użytkownik",
      isQuarantine: "Kwaratanna",
      desc: "Informacje dodatkowe"
    },
    loginHistoryReport: {
      eventDate: "Data zdarzenia",
      eventDescription: "Zdarzenie",
      loginHistoryFrom: "Historia logowania od"
    },
    operationHistoryReport: {
      operationHistoryFrom: "Historia operacji od"
    },
    identityServer: {
      sendPasswordWithEmail:
        "Na podany adres e-mail została wysłana wiadomość z wygenerowanym hasłem",
      userExistsInIdentity:
        "Użytkownik o podanym loginie istnieje w serwerze autentykacji"
    },
    changePassword: {
      changePassword: "Zmiana hasła",
      currentPassword: "Aktualne hasło",
      newPassword: "Nowe hasło",
      confirmNewPassword: "Potwierdź nowe hasło",
      passwordsMismatch: "Wpisane hasła są różne",
      passwordChanged: "Hasło na serwerze autentykacji zostało zmienione"
    }
  }
};
