import Vue from "vue";
import VueRouter from "vue-router";
import store from "./store/index";
import appSettings from "./configuration";
import ReportRoutesFactory from "./reports-router.factory";

Vue.use(VueRouter);

function loadView(view) {
  return () =>
    import(/* webpackChunkName: "view-[request]" */ `@/views/${view}.vue`);
}

const routes = [
  {
    path: "/",
    component: loadView("Master"),
    children: [
      { path: "", redirect: "/rodo" },
      { path: "/rodo", name: "rodo", component: loadView("RodoList") },
      {
        path: "",
        name: "admin",
        component: loadView("AdministrativeActivities"),
        children: [
          {
            path: "/users",
            name: "users",
            component: loadView("Users")
          },
          {
            path: "/users/create",
            name: "createUser",
            component: loadView("NewUser")
          },
          {
            path: "/users/:userId",
            name: "user",
            component: loadView("NewUser"),
            props: true
          },
          {
            path: "/roles",
            name: "roles",
            component: loadView("Roles")
          },
          {
            path: "/roles/create",
            name: "createRole",
            component: loadView("NewRole")
          },
          {
            path: "/roles/:roleId",
            name: "role",
            component: loadView("NewRole"),
            props: true
          },
          {
            path: "/backup",
            name: "backup",
            component: loadView("Backup")
          }
        ]
      },
      { path: "/tasks", name: "tasks", component: loadView("Tasks") },
      {
        path: "/reports",
        name: "reports",
        component: loadView("Reports"),
        children: appSettings.reports.map((r) => ReportRoutesFactory.get(r))
      },
      {
        path: "/changePassword",
        name: "changePassword",
        component: loadView("ChangePassword")
      }
    ]
  },
  {
    path: "/login",
    name: "login",
    component: loadView("Login"),
    meta: {
      public: true,
      onlyWhenLoggedOut: true
    }
  },
  {
    path: "/oidc-callback", // Needs to match redirect_uri in you oidcSettings
    name: "oidcCallback",
    component: loadView("OidcCallback"),
    meta: {
      isOidcCallback: true,
      isPublic: true
    }
  },
  {
    path: "/silent-refresh-oidc",
    name: "silent-refresh-oidc",
    component: loadView("OidcSilentRefresh"),
    meta: {
      isVuexOidcCallback: true,
      isOidcCallback: true,
      isPublic: true
    }
  },
  { path: "*", redirect: "/", meta: { public: true } }
];

function beforeEach(to, from, next) {
  if (appSettings.useAD) {
    if (to.name == "login") {
      return next("/");
    }

    store.dispatch("oidcStore/oidcCheckAccess", to).then(hasAccess => {
      if (hasAccess) {
        next();
      }
    });
  } else {
    const isPublic = to.matched.some(record => record.meta.public);
    const onlyWhenLoggedOut = to.matched.some(
      record => record.meta.onlyWhenLoggedOut
    );
    const isLogged = store.getters.isUserLogged;

    if (!(isPublic || isLogged)) {
      // == !isPublic && !isLogged
      return next({
        path: "/login",
        query: {
          redirect: to.fullPath // Store the full path to redirect the user to after login
        }
      });
    }
    // Do not allow user to visit login page if they are logged in
    if (isLogged && onlyWhenLoggedOut) {
      return next("/");
    }
    next();
  }
}

export function CreateRouter(basePath = "/") {
  let router = new VueRouter({
    routes,
    mode: "history",
    base: basePath
  });
  router.beforeEach(beforeEach);
  return router;
}
