import "vuetify/dist/vuetify.min.css";
import pl from "vuetify/es5/locale/pl";
import Vue from "vue";
import VueI18n from "vue-i18n";
import Vuetify from "vuetify";
import { icons } from "./icons";
import { messages } from "./messages/messages";

Vue.use(VueI18n);

export const i18n = new VueI18n({
  locale: "pl",
  messages
});

Vue.use(Vuetify, {
  ...icons,
  lang: {
    t: (key, ...params) => i18n.t(key, params)
  },
  options: {
    customProperties: true
  },
  theme: {
    primary: "#1976D2",
    secondary: "#ececec",
    accent: "#eeeeee",
    error: "#e32e25",
    info: "#2196F3",
    success: "#74B236",
    warning: "#EF8D00"
  }
});
