import {
  CONTACT_AGREEMENT,
  LOGIN_HISTORY,
  OPERATION_HISTORY,
  CONTACT_QUARANTINE
} from "./types/reports.types";

function loadView(view) {
  return () =>
    import(/* webpackChunkName: "view-[request]" */ `@/views/${view}.vue`);
}
const reports = {
  contactAgreementReport: {
    path: "contactAgreement",
    name: CONTACT_AGREEMENT,
    component: loadView("ReportContactAgreement")
  },
  loginHistoryReport: {
    path: "loginHistory",
    name: LOGIN_HISTORY,
    component: loadView("ReportLoginHistory")
  },
  operationHistoryReport: {
    path: "operationHistory",
    name: OPERATION_HISTORY,
    component: loadView("ReportOperationHistory")
  },
  contactQuarantineReport: {
    path: "contactQuarantine",
    name: CONTACT_QUARANTINE,
    component: loadView("ReportContactQuarantine")
  }
};
export default {
  get: name => reports[name]
};
