import "./appstyle";
import Vue from "vue";
import store from "./store";
import { CreateRouter } from "./router";
import { i18n } from "./appstyle";
import { EmitWarning } from "./event-bus";
import "./permissions";
import { LoadConfiguration } from "@/services/ConfigurationService";
import { SetOptions } from "@/configuration";
import { registerOidcStore } from "@/store/oidc";
import vuetify from "./plugins/vuetify";

import App from "./App.vue";

LoadConfiguration().then(response => {
  SetOptions(response);
  let router = CreateRouter(response.SubFolder);
  store.registerModule("oidcStore", registerOidcStore());
  const app = new Vue({
    created() {
      // on localStorage external change
      this.$ls.on("user", function(newUser, oldUser) {
        // IE is dumb and it triggers this event for its own localStorage changes
        if (JSON.stringify(newUser) === JSON.stringify(oldUser)) return;
        const isUserLogged = store.getters.isUserLogged;
        // user has logged out/token expired/localStorage clean up
        if (!newUser && isUserLogged) {
          store.commit("RESET_STATE");
          EmitWarning(i18n.t("login.session_expired"));
          router.push({ name: "login" });
        } else if (newUser) {
          store.commit("SET_USER", newUser);
          if (!isUserLogged) {
            router.push({ name: "rodo" });
          }
        }
      });
    },
    i18n,
    router,
    store,
    vuetify,
    render: (h) => h(App),
  }).$mount("#app");
});
