import axios from "../httpConfig/http.js";

const urlApi = "api/tasks";

export default {
  GetTasks() {
    return axios.get(`${urlApi}`).then(function(response) {
      return response;
    });
  }
};
