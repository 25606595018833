import "@fortawesome/fontawesome-free/css/all.css";

import Vue from "vue";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faKickstarter } from "@fortawesome/free-brands-svg-icons";

library.add(fas);
library.add(faKickstarter);

Vue.component("fa-icon", FontAwesomeIcon);

const MY_ICONS = {
  // complete: '',
  // cancel: 'fas fa-ban',
  // close: '',
  // delete: '', // delete (e.g. v-chip close)
  clear: "fas fa-times fa-sm"
  // success: '',
  // info: '',
  // warning: '',
  // error: '',
  // prev: '',
  // next: '',
  // checkboxOn: 'fas fa-cat',
  // checkboxOff: 'fas fa-bars',
  // checkboxIndeterminate: 'fas fa-cat',
  // delimiter: '', // for carousel
  // sort: 'fas fa-angle-up',
  // expand: '',
  // menu: 'fas fa-bars',
  // subgroup: '',
  // dropdown: '',
  // radioOn: '',
  // radioOff: '',
  // edit: '',
};

export const icons = {
  iconfont: "fa",
  values: MY_ICONS
};
