import Vue from "vue";
import Vuex from "vuex";
import Storage from "vue-ls";
import LoginService from "@/services/LoginService";
import RolesService from "@/services/RolesService";
import TasksService from "@/services/TasksService";
import appSettings from "@/configuration";

Vue.use(Storage);
Vue.use(Vuex);

let defaultState = () => {
  return {
    rolesTable: { selected: [] },
    usersTable: { selected: [] },
    item: null,
    user: null,
    restrictions: [],
    expirationContacts: []
  };
};
const store = new Vuex.Store({
  state: defaultState(),
  modules: {},
  getters: {
    isUserLogged: state => {
      return state.user !== null;
    },
    taskCount: state => {
      let count = state.expirationContacts.length;
      return count > 99 ? "99+" : count;
    }
  },
  mutations: {
    INITIALISE_STORE(state) {
      const lsUser = Vue.ls.get("user");
      if (lsUser) {
        this.commit("SET_USER", lsUser);
      }
    },
    RESET_STATE(state) {
      this.replaceState(defaultState());
    },
    SET_USER(state, user) {
      state.user = user;
    },
    SET_NEW_TOKEN(state, newToken) {
      if (state.user !== null) {
        state.user.accessToken = newToken.accessToken;
        state.user.refreshToken = newToken.refreshToken;
      }
    },
    SET_ROLESTABLE_SELECTED(state, selected) {
      state.rolesTable.selected = selected;
    },
    SET_USERSTABLE_SELECTED(state, selected) {
      state.usersTable.selected = selected;
    },
    SET_RESTRICTIONS(state, restrictions) {
      state.restrictions = restrictions;
    },
    SET_TASKS(state, value) {
      state.expirationContacts = value;
    }
  },
  actions: {
    RESET_STATE({ commit, state, dispatch }, isAutomatic) {
      isAutomatic
        ? LoginService.LogoutAutomatic(state.user.login)
        : LoginService.Logout(state.user.login);
      if (appSettings.useAD) {
        dispatch("oidcStore/signOutOidc");
        commit("RESET_STATE");
      } else {
        commit("RESET_STATE");
        return "login";
      }
    },
    LOGIN({ commit }, { userName, password }) {
      return LoginService.Login(userName, password).then(data => {
        if (!data.isError) {
          commit("SET_USER", data);
        }
        return data;
      });
    },
    REFRESH_TOKEN({ dispatch, commit, state }) {
      return LoginService.RefreshToken(state.user.refreshToken).then(data => {
        if (data.isError) dispatch("RESET_STATE", true);
        else commit("SET_NEW_TOKEN", data);
        return { data: data.accessToken, isError: data.isError };
      });
    },
    SET_ROLESTABLE_SELECTED({ commit }, selected) {
      commit("SET_ROLESTABLE_SELECTED", selected);
    },
    SET_USERSTABLE_SELECTED({ commit }, selected) {
      commit("SET_USERSTABLE_SELECTED", selected);
    },
    SET_RESTRICTIONS({ commit, state }) {
      if (state.restrictions[0]) {
        return state.restrictions;
      }
      return RolesService.GetRestrictionsList().then(response => {
        commit("SET_RESTRICTIONS", response);
        return response;
      });
    },
    SET_TASKS({ commit }) {
      TasksService.GetTasks().then(response => {
        commit("SET_TASKS", response);
      });
    }
  }
});

store.commit("INITIALISE_STORE");

store.subscribe((mutation, state) => {
  const userChangingMutations = ["SET_USER", "SET_NEW_TOKEN", "RESET_STATE"];
  if (userChangingMutations.includes(mutation.type)) {
    Vue.ls.set("user", state.user);
  }
});

export default store;
