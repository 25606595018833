import Vue from "vue";
import Acl from "vue-browser-acl";
import store from "./store";
// example user from backend, you can provide a function
// instead in case the user retrieved asynchronously
const user = () => store.state.user;

Vue.use(Acl, user, acl => {
  acl.rule("admin", user => user.isAdmin);
  acl.rule("hasRestriction", {}, (user, restrictionObj) => {
    return user.restrictions.includes(restrictionObj.code);
  });
});
