import store from "../store/index";
import appSettings from "../configuration";
export function authHeader() {
  // return authorization header with jwt token
  let token = authToken();

  if (token) {
    return { Authorization: token };
  } else {
    return {};
  }
}

export function authToken() {
  let token = "";
  if (appSettings.useAD) {
      token = (store.state.oidcStore || {}).access_token;
  } else {
    token = (store.state.user || {}).accessToken;
  }
  return `Bearer ${token}`;
}
