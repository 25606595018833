import Vue from "vue";
export const EventBus = new Vue();

export function EmitError(msg) {
  EventBus.$emit("eb-error", msg);
}

export function EmitWarning(msg) {
  EventBus.$emit("eb-warning", msg);
}

export function EmitSuccess(msg) {
  EventBus.$emit("eb-success", msg);
}

export function EmitInfo(msg) {
  EventBus.$emit("eb-info", msg);
}
