<template>
  <v-app>
    <v-main>
      <Snackbar />
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import Snackbar from "./components/Snackbar";

export default {
  components: {
    Snackbar
  }
};
</script>

<style lang="scss">
@import "./assets/style.scss";
</style>
