import {
  CONTACT_AGREEMENT,
  LOGIN_HISTORY,
  OPERATION_HISTORY,
  CONTACT_QUARANTINE
} from "./types/reports.types";

const appSettings = {
  useAD: true,
  reports: [
    CONTACT_AGREEMENT,
    LOGIN_HISTORY,
    OPERATION_HISTORY,
    CONTACT_QUARANTINE
  ]
};

export default appSettings;

export const oidcSettings = {
  authority: "https://auth.pirios.com",
  client_id: window.location.origin
    .replace("http://", "")
    .replace("https://", ""),
  redirect_uri: window.location.origin + "/oidc-callback",
  response_type: "id_token token",
  scope: "openid profile api",
  automaticSilentRenew: true,
  silent_redirect_uri: window.location.origin + "/silent-refresh-oidc"
};

export function SetOptions(options) {
  oidcSettings.authority = options.IdentityServerUrl;
  oidcSettings.client_id = generateClientId(options);
  oidcSettings.silent_redirect_uri =
    generateSiteUrl(options) + "/silent-refresh-oidc";
  oidcSettings.redirect_uri = generateSiteUrl(options) + "/oidc-callback";
  appSettings.useAD = options.UseAd;
}

function generateClientId(options) {
  let clientId = window.location.origin
    .replace("http://", "")
    .replace("https://", "");
  if (options.ClientId.length > 0) {
    clientId = options.ClientId;
  }
  return clientId;
}

function generateSiteUrl(options) {
  let siteUrl = window.location.origin;
  if (options.SubFolder.length > 0) {
    siteUrl = siteUrl.concat(
      options.SubFolder.startsWith("/")
        ? options.SubFolder
        : "/" + options.SubFolder
    );
  }
  return siteUrl;
}
