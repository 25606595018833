import axios from "axios";
import store from "../store/index";
import { router } from "../router";
import { i18n } from "../appstyle";
import { EmitWarning } from "../event-bus";

let isRefreshing = false;
let subscribers = [];
export default {
  localAuthorizationService({ status, headers }, originalRequest) {
    if (status === 403) {
      router.push({ name: "login" });
    }
    if (status === 401 && !headers["token-expired"]) {
      router.push({ name: "login" });
    }
    if (status === 401 && headers["token-expired"] === "true") {
      console.log("IsRefreshing: " + isRefreshing);
      if (!isRefreshing) {
        isRefreshing = true;
        store.dispatch("REFRESH_TOKEN").then(r => {
          if (r.isError) {
            console.error("Failed to refresh token.");
            EmitWarning(i18n.t("login.session_expired"));
            isRefreshing = false;
            subscribers = [];
            router.push({ name: "login" });
          } else {
            onRrefreshed(r.data);
            isRefreshing = false;
            subscribers = [];
          }
        });
      }
      const requestSubscribers = new Promise(resolve => {
        subscribeTokenRefresh(token => {
          originalRequest.headers.Authorization = `Bearer ${token}`;
          resolve(axios(originalRequest));
        });
      });
      return requestSubscribers;
    }
  }
};

function subscribeTokenRefresh(cb) {
  subscribers.push(cb);
}

function onRrefreshed(token) {
  subscribers.map(cb => cb(token));
}
