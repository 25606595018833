import Vue from "vue";
import Vuetify from "vuetify/lib";
import pl from "vuetify/es5/locale/pl";
import { icons } from "@/icons";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: "#1976D2",
        secondary: "#ececec",
        accent: "#eeeeee",
        error: "#e32e25",
        info: "#2196F3",
        success: "#74B236",
        warning: "#EF8D00"
      }
    }
  },
  lang: {
    locales: { pl },
    current: "pl"
  },
  icons: icons
});
