import axios from "axios";
import { authToken } from "./auth-header";
import { EmitError } from "../event-bus";
import appSettings from "../configuration";
import local from "../httpConfig/local-unauthorize";
import store from "../store/index";

// Add a request interceptor
axios.interceptors.request.use(
  function(config) {
    // Do something before request is sent
    config.headers.common["Authorization"] = authToken();
    config.headers.common["Cache-Control"] = "no-cache";
    config.headers.get["If-Modified-Since"] = "0";
    config.timeout = 300000;
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  function(response) {
    // if success
    if (response.status === 200) {
      if (
        typeof response.data.isValid === "boolean" &&
        !response.data.isValid
      ) {
        EmitError(response.data.errors);
      }
      return response.data;
    }
    return response;
  },
  function(error) {
    // if unauthorized redirect to login
    const { config, response } = error;
    const originalRequest = config;
    if (!appSettings.useAD) {
      return local.localAuthorizationService(response, originalRequest);
    }
    if (store.hasModule("oidcStore")) {
      if (!store.state.oidcStore.access_token) {
        return store.dispatch("RESET_STATE");
      }
    }
    throw error;
  }
);

export default axios;
