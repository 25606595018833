<template>
  <div>
    <v-snackbar
      v-model="notification"
      top
      :color="color"
      :timeout="timeout"
      :multi-line="isMultiple"
    >
      <v-layout v-if="isMultiple" column>
        <ul>
          <li v-for="(item, index) in notificationText" :key="index">
            {{ item }}
          </li>
        </ul>
      </v-layout>
      <v-layout v-if="!isMultiple">{{ singleValue }}</v-layout>
    </v-snackbar>
  </div>
</template>

<script>
import { EventBus } from "../event-bus.js";

export default {
  name: "Snackbar",
  data() {
    return {
      notificationText: "",
      notificationQueue: [],
      notification: false,
      color: null,
      timeout: 5000
    };
  },
  computed: {
    hasNotificationsPending() {
      return this.notificationQueue.length > 0;
    },
    isMultiple() {
      return (
        typeof this.notificationText === "object" && !!this.notificationText[1]
      );
    },
    singleValue() {
      return typeof this.notificationText === "object"
        ? this.notificationText.join(", ")
        : this.notificationText;
    }
  },
  watch: {
    notification() {
      if (!this.notification && this.hasNotificationsPending) {
        this.buildNotification(this.notificationQueue.shift());
        this.$nextTick(() => (this.notification = true));
      }
    }
  },
  mounted() {
    EventBus.$on("eb-error", msg => {
      this.addNotification(msg, "error");
    });
    EventBus.$on("eb-warning", msg => {
      this.addNotification(msg, "warning");
    });
    EventBus.$on("eb-success", msg => {
      this.addNotification(msg, "success");
    });
    EventBus.$on("eb-info", msg => {
      this.addNotification(msg);
    });
  },
  methods: {
    addNotification(text, color = null, timeout = 5000) {
      this.notificationQueue.push({ text, color, timeout });
      if (!this.notification) {
        this.buildNotification(this.notificationQueue.shift());
        this.notification = true;
      }
    },
    buildNotification(notification) {
      this.notificationText = notification.text;
      this.color = notification.color;
      this.timeout = notification.timeout;
    }
  }
};
</script>
