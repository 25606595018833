import axios from "../httpConfig/http";

const urlApi = "api/login";

export default {
  GetUser({ userId, name }) {
    return axios.get(`${urlApi}/${userId}/${name}`);
  },
  Login(username, password) {
    return axios.post(`${urlApi}`, { Login: username, Password: password });
  },
  Logout(userName) {
    return axios.post(`${urlApi}/logout`, { userName });
  },
  LogoutAutomatic(userName) {
    return axios.post(`${urlApi}/logout/automatic`, { userName });
  },
  RefreshToken(refreshToken) {
    return axios.post(`${urlApi}/refresh`, { RefreshToken: refreshToken });
  },
};
