import axios from "../httpConfig/http";
import store from "../store/index";

const urlApi = "api/roles";

export default {
  GetRoles() {
    return axios.get(`${urlApi}`);
  },
  GetRestrictionsList() {
    return axios.get(`${urlApi}/restrictions`);
  },
  GetRoleRestrictions(roleId) {
    return axios.get(`${urlApi}/${roleId}/restrictions`);
  },
  GetRoleDetails(roleId) {
    return axios.get(`${urlApi}/${roleId}`);
  },
  CreateRole(roleName, restrictions) {
    return axios.put(`${urlApi}`, {
      roleName,
      restrictions,
      userName: store.state.user.login
    });
  },
  UpdateRole(roleId, roleName, restrictions) {
    return axios.post(`${urlApi}/${roleId}`, {
      roleId,
      roleName,
      restrictions,
      userName: store.state.user.login
    });
  },
  DeleteRole(roleArray) {
    return axios.delete(`${urlApi}/`, {
      data: {
        data: roleArray,
        userName: store.state.user.login
      }
    });
  }
};
